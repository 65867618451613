export const scrollNav = () => {
  const sections = document.querySelectorAll(".section");
  const scrollNav = document.querySelector("#scroll-nav");
  const hero = document.querySelector('.hero');

  const sectionObserver = new IntersectionObserver(doWhenSectionIntersect, {
    root: null,
    rootMargin: "-50% 0px",
    threshold: 0,
  });

  const heroObserver = new IntersectionObserver(doWhenHeroIntersect, {
    root: null,
    rootMargin: "-50% 0px",
    threshold: 0,
  });

  sections.forEach((section) => {
    sectionObserver.observe(section);
  });

  heroObserver.observe(hero);

  function doWhenSectionIntersect(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        activateIndex(entry.target);
      }
    });
  }

  function doWhenHeroIntersect(entries){
    const hideScrollnav = entries[0].isIntersecting;
    if (hideScrollnav) {
      scrollNav.classList.add("hidden");
    } else {
      scrollNav.classList.remove("hidden");
    }
  }

  function activateIndex(element) {
    const currentActiveIndex = document.querySelector(".scroll-nav .is-active");
    if (currentActiveIndex !== null) {
      currentActiveIndex.classList.remove("is-active");
    }

    if (element.id && element.id != '') {
      const newActiveIndex = document.querySelector(`a[href='#${element.id}']`);
      newActiveIndex.classList.add("is-active");

      document.querySelectorAll(`#${element.id} .aos-init`).forEach((section) => {
        section.classList.add('aos-animate')
      })
    }
  }

  const slides = document.querySelectorAll(".slide");

  // loop through slides and set each slides translateX property to index * 100% 
  slides.forEach((slide, indx) => {
    slide.style.transform = `translateX(${indx * 100}%)`;
  });

  // current slide counter
  let curSlide = 0;
  let maxSlide = slides.length - 1;

  // select next slide button
  const nextSlide = document.querySelector(".btn-next");

  // add event listener and next slide functionality
  nextSlide.addEventListener("click", function () {
    if (curSlide === maxSlide) {
      curSlide = 0;
    } else {
      curSlide++;
    }

    if (curSlide === maxSlide) {
      nextSlide.classList.add('hidden');
    }
    if (curSlide > 0) {
      prevSlide.classList.remove('hidden');
    }
  
    //   move slide by -100%
    slides.forEach((slide, indx) => {
      slide.style.transform = `translateX(${100 * (indx - curSlide)}%)`;
    });
  });

  // select prev slide button
  const prevSlide = document.querySelector(".btn-prev");
  prevSlide.classList.add('hidden');

  // add event listener and navigation functionality
  prevSlide.addEventListener("click", function () {
    // check if current slide is the first and reset current slide to last
    if (curSlide === 0) {
      curSlide = maxSlide;
    } else {
      curSlide--;
    }

    if (curSlide === 0) {
      prevSlide.classList.add('hidden');
    } else {
      nextSlide.classList.remove('hidden');
    }

    //   move slide by 100%
    slides.forEach((slide, indx) => {
      slide.style.transform = `translateX(${100 * (indx - curSlide)}%)`;
    });
  });

  const colorItems = document.querySelectorAll(".color-item");

  colorItems.forEach(function (item) {
    item.addEventListener("click", function (event) {
      colorItems.forEach(function (item) {
        item.classList.remove('active')
      })
      event.srcElement.classList.add('active')

      const classList = event.srcElement.classList
      console.log(classList[2])

      document.getElementById("color").value = classList[2]
    })
  })
};
